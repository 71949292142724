@use 'common'

@font-face
	font-family: 'switzer'
	src: url('/fonts/switzer-bold-webfont.woff2') format('woff2'), url('/fonts/switzer-bold-webfont.woff') format('woff')
	font-weight: 700
	font-style: normal

@font-face
	font-family: 'switzer'
	src: url('/fonts/switzer-medium-webfont.woff2') format('woff2'), url('/fonts/switzer-medium-webfont.woff') format('woff')
	font-weight: 500
	font-style: normal

@font-face
	font-family: 'switzer'
	src: url('/fonts/switzer-regular-webfont.woff2') format('woff2'), url('/fonts/switzer-regular-webfont.woff') format('woff')
	font-weight: 400
	font-style: normal

html,
body
	color: common.$niceColor
	padding: 0
	margin: 0
	font-family: switzer, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

a
	color: inherit
	text-decoration: none
	font-family: switzer, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

*
	box-sizing: border-box
